<template>
  <section>
    <e-filters
      :searching="fetching"
      @search="filterData"
      @reset="resetFiltersLocal"
    >
      <b-row>
        <b-col md="2">
          <FormulateInput
            id="code"
            v-model="filter.code"
            :label="$t('Código')"
            :placeholder="$t('Código')"
          />
        </b-col>
        <b-col md="4">
          <FormulateInput
            id="description"
            v-model="filter.description"
            :label="$t('Descrição')"
            :placeholder="$t('Descrição')"
          />
        </b-col>
        <b-col md="3">
          <FormulateInput
            id="movement_type"
            v-model="filter.movementType"
            type="vue-select"
            :options="allMovementTypeOptions()"
            :label="$t('Tipo de movimento')"
            :placeholder="$t('Selecione')"
          />
        </b-col>
        <b-col md="3">
          <FormulateInput
            id="cfop_type"
            v-model="filter.cfopType"
            :options="cfopTypeOptions()"
            type="vue-select"
            :label="$t('Tipo de CFOP')"
            :placeholder="$t('Selecione')"
          />
        </b-col>
        <b-col md="2">
          <FormulateInput
            id="active"
            v-model="filter.active"
            name="active"
            :label="$t('Status')"
            :placeholder="$t('Todos')"
            type="vue-select"
            :options="activeInactiveTypes()"
          />
        </b-col>
      </b-row>
    </e-filters>

    <b-card-actions
      action-refresh
      :show-loading="fetching"
      @refresh="getData"
    >
      <div slot="title">
        <e-page-size-selector
          :per-page="paging.pageSize"
          @change="pageSizeChange"
        />
      </div>
      <b-table
        ref="cfops-table"
        show-empty
        responsive
        striped
        class="bordered"
        :empty-text="getEmptyTableMessage($tc('CFOP.NAME'))"
        :fields="fields"
        :items="cfops"
        no-local-sorting
        :sort-by.sync="sorting.sortBy"
        :sort-desc.sync="sorting.sortDesc"
        @sort-changed="getData"
      >
        <template #cell(active)="row">
          <e-status-badge
            :status="row.item.active"
            type="yesNo"
          />
        </template>

        <template #cell(action)="row">
          <e-grid-actions
            :is-active="row.item.active"
            :show-update="$can('Update', 'Cfop')"
            :show-delete="false"
            :show-activate="$can('Activate', 'Cfop')"
            :show-deactivate="$can('Deactivate', 'Cfop')"
            @update="updateCfop(row)"
            @activate="activateOrDeactivateCfop(row)"
            @deactivate="activateOrDeactivateCfop(row)"
          />
        </template>
        <!-- <template
          #cell(action)="row"
        >
          <b-button
            size="sm"
            @click="updateRole(row)"
          >
            {{ $t('Editar') }}
          </b-button>
          <span></span>
          <b-button
            size="sm"
            @click="updateRole(row)"
          >
            {{ $t('Ativar') }}
          </b-button>
        </template> -->
      </b-table>
      <b-row>
        <b-col cols="6">
          <e-pagination-summary
            :current-page="paging.currentPage"
            :per-page="paging.pageSize"
            :total="paging.rowCount"
            :total-on-page="paging.rowsInCurrentPage"
          />
        </b-col>
        <b-col cols="6">
          <b-pagination
            v-model="paging.currentPage"
            align="right"
            :total-rows="paging.rowCount"
            :per-page="paging.pageSize"
            aria-controls="cfops-table"
            @change="pageChange"
          />
        </b-col>
      </b-row>
    </b-card-actions>
    <fab
      :main-tooltip="$t('Adicionar CFOP')"
      @click="create"
    />
  </section>
</template>

<script>
import { BTable, BPagination, BRow, BCol } from 'bootstrap-vue'
import fab from '@/views/components/FAB.vue'
import BCardActions from '@core/components/b-card-actions/BCardActions.vue'
import Ripple from 'vue-ripple-directive'
import { EPaginationSummary, EPageSizeSelector } from '@/views/components'
import { invoiceDomains, stringUtils, statusTypes } from '@/mixins'
import { mapActions, mapState } from 'vuex'
import EStatusBadge from '@/views/components/EStatusBadge.vue'
import EFilters from '@/views/components/EFilters.vue'
import EGridActions from '@/views/components/EGridActions.vue'

export default {
  components: {
    BTable,
    BPagination,
    BRow,
    BCol,
    EPaginationSummary,
    EPageSizeSelector,
    BCardActions,
    fab,
    EStatusBadge,
    EFilters,
    EGridActions,
  },
  directives: { Ripple },
  mixins: [stringUtils, invoiceDomains, statusTypes],

  data() {
    return {
      loaded: false,
      fetching: false,
    }
  },

  computed: {
    ...mapState('pages/invoice/cfops', ['cfops', 'paging', 'sorting', 'filter']),

    fields() {
      return [
        {
          label: this.$t('Ações'),
          key: 'action',
          class: 'text-center',
          thStyle: { width: '100px' },
        },
        {
          label: this.$t('Código'),
          key: 'code',
          class: 'text-center',
          sortable: true,
        },
        {
          label: this.$t('Descrição'),
          key: 'description',
          sortable: true,
        },
        {
          label: this.$t('Tipo de Movimento'),
          key: 'movementType',
          class: 'text-center',
          formatter: value => this.movementTypeLabel[value],
          sortable: true,
        },
        {
          label: this.$t('Tipo de Cfop'),
          key: 'cfopType',
          class: 'text-center',
          formatter: value => this.cfopTypeLabel[value],
          sortable: true,
        },
        {
          label: this.$t('Ativo'),
          key: 'active',
          class: 'text-center',
          sortable: true,
        },
      ]
    },
  },

  mounted() {
    if (!this.cfops.length) {
      this.getData()
    }
    setTimeout(() => {
      this.loaded = true
    }, 500)
  },

  methods: {
    ...mapActions('pages/invoice/cfops', [
      'fetchCfops',
      'setCurrentPage',
      'setPageSize',
      'resetFilter',
    ]),

    async getData() {
      this.$nextTick(async () => {
        try {
          this.fetching = true
          await this.fetchCfops()
        } catch (error) {
          this.showGenericError({ error })
        } finally {
          this.fetching = false
        }
      })
    },
    async filterData() {
      this.setCurrentPage(1)
      await this.getData()
    },
    async activateOrDeactivateCfop(row) {
      try {
        const confirm = await this.confirm()
        if (confirm) {
          await this.$http({
            url: `/api/tax/cfops/${row.item.active === true ? 'deactivate' : 'activate'}/${
              row.item.code
            }`,
            method: 'PUT',
          })
          this.getData()
          this.showSuccess({
            message: this.$t(
              `Cfop ${row.item.active === true ? 'inativado' : 'ativado'} com sucesso`
            ),
          })
        }
      } catch (error) {
        this.showError({ error })
      }
    },
    pageSizeChange(pageSize) {
      this.setPageSize(pageSize)
      this.getData()
    },
    pageChange(currentPage) {
      this.setCurrentPage(currentPage)
      this.getData()
    },
    resetFiltersLocal() {
      this.resetFilter()
      this.setCurrentPage(1)
      this.getData()
    },
    create() {
      this.$router.push({
        name: 'cfop-add',
      })
    },
    updateCfop(cfop) {
      this.$router.push({
        name: 'cfop-maintain',
        params: { id: cfop.item.code },
      })
    },
  },
}
</script>
